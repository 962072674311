.sa-next-button {
  @apply tw-absolute tw-bottom-0 tw-right-0 tw-m-6 tw-cursor-pointer tw-rounded-lg tw-border-none tw-bg-neutral-900 tw-px-5 tw-py-2 tw-font-sans tw-text-lg tw-text-white;
}

.sa-prev-button {
  @apply tw-absolute tw-bottom-0 tw-left-0 tw-m-6 tw-cursor-pointer tw-rounded-lg tw-border-none tw-bg-neutral-900 tw-px-5 tw-py-2 tw-font-sans tw-text-lg tw-text-white;
}

.sa-close-button {
  @apply tw-absolute tw-right-0 tw-top-0 tw-m-4 tw-cursor-pointer tw-border-none tw-px-4 tw-py-2 tw-font-sans tw-text-4xl tw-text-hue-900;
}

.sa-close-button_dark {
  @apply tw-absolute tw-right-0 tw-top-0 tw-m-4 tw-cursor-pointer tw-border-none tw-px-4 tw-py-2 tw-font-sans tw-text-4xl tw-text-white;
}

.sa-body {
  @apply tw-p-10 tw-font-sans;
}

.sa-header {
  @apply tw-my-5 tw-font-sans tw-text-4xl tw-font-bold;
}

.step-box-container {
  @apply tw-mb-5 tw-w-full tw-rounded-lg tw-bg-white tw-p-4 sm:tw-p-6;
}

.step-box-container_dark {
  @apply tw-mb-5 tw-w-full tw-rounded-lg tw-bg-neutral-800 tw-p-4 sm:tw-p-6;
}
