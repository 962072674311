// css for elements within datatable class
.datatable {
  @apply tw-mb-3  
      tw-text-left
      tw-font-sans;

  // description box
  .description-box {
    @apply tw-my-4 
      tw-flex 
      tw-flex-col 
      tw-gap-4 
      tw-rounded-lg 
      tw-p-4
      tw-text-neutral-700;
  }

  // the action header of the datatable
  .action-header {
    @apply tw-mb-2
            tw-flex
            tw-h-[45px]
            tw-items-center
            tw-justify-between;
  }

  // datatable table
  table {
    @apply tw-w-full 
            tw-border-collapse 
            tw-border;
  }

  // datatable header
  th {
    @apply tw-border 
            tw-p-2 
            tw-font-semibold 
            tw-tracking-wider;
  }

  // datatable footer
  .datatable-footer {
    @apply tw-h-1 
            tw-border 
            tw-p-1;
  }

  // datatable body row data
  tbody tr td {
    @apply tw-border tw-p-2;
  }

  // css for column resizer
  .resizer {
    @apply tw-absolute
            tw-right-0
            tw-top-0
            tw-h-full
            tw-w-1
            tw-cursor-col-resize;
  }
}

// light mode specific datatable css
.datatable_light {
  // datatable table (light)
  table {
    @apply tw-border-hue-200 tw-text-hue-700;
  }

  // datatable header (light)
  th {
    @apply tw-bg-hue-100;
  }

  // datatable body rows (light)
  tbody tr {
    // on odd rows
    &:nth-of-type(odd) {
      @apply tw-bg-white;
    }

    // on odd even
    &:nth-of-type(even) {
      @apply tw-bg-hue-50;
    }
  }

  // datatable footer (light)
  .datatable-footer {
    @apply tw-bg-hue-100;
  }
}

// dark mode specific datatable css
.datatable_dark {
  // datatable table (dark)
  table {
    @apply tw-border-hue-600 tw-text-white;
  }

  // datatable header (dark)
  th {
    @apply tw-bg-hue-700;
  }

  // datatable body rows (dark)
  tbody tr {
    // on odd rows
    &:nth-of-type(odd) {
      @apply tw-bg-hue-900;
    }

    // on odd even
    &:nth-of-type(even) {
      @apply tw-bg-hue-800;
    }
  }

  // datatable footer (dark)
  .datatable-footer {
    @apply tw-bg-hue-700;
  }
}
