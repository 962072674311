.accordion-container {
  @apply tw-m-2 tw-mx-auto tw-w-full tw-rounded tw-border tw-bg-white tw-p-2 tw-shadow-md
        hover:tw-shadow-lg;
}

.accordion-container_dark {
  @apply tw-m-2 tw-mx-auto tw-w-full tw-rounded tw-border tw-bg-hue-950 tw-p-2 tw-text-white tw-shadow-md
        hover:tw-shadow-lg;
}

.accordion-button {
  @apply tw-block tw-w-full tw-rounded tw-bg-white tw-px-4 tw-py-2
        hover:tw-bg-hue-200 hover:tw-underline;
}

.accordion-button_dark {
  @apply tw-block tw-w-full tw-rounded tw-bg-hue-950 tw-px-4 tw-py-2
        hover:tw-bg-hue-700 hover:tw-underline;
}

.accordion-button_open {
  @apply tw-block tw-w-full tw-rounded tw-bg-hue-100 tw-px-4 tw-py-2
        hover:tw-bg-hue-200 hover:tw-underline focus:tw-outline-none focus-visible:tw-ring 
        focus-visible:tw-ring-hue-500 focus-visible:tw-ring-opacity-75;
}

.accordion-button_open_dark {
  @apply tw-block tw-w-full tw-rounded tw-bg-hue-800 tw-px-4 tw-py-2
        hover:tw-bg-hue-700 hover:tw-underline focus:tw-outline-none focus-visible:tw-ring 
        focus-visible:tw-ring-hue-500 focus-visible:tw-ring-opacity-75;
}

.accordion-title {
  @apply tw-float-left tw-text-hue-900;
}

.accordion-title_dark {
  @apply tw-float-left tw-text-white;
}

.accordion-chevron {
  @apply tw-float-right tw-h-6 tw-w-6 tw-text-base tw-transition-transform;
}

.accordion-panel {
  @apply tw-px-4 tw-pb-2 tw-pt-4 tw-text-sm;
}
