// Forms
.form-control-container {
  // @apply tw-mb-2;
}

.form-control {
  // The style of form control is designed to make the control have a height of 45px with appropriate padding
  @apply tw-m-auto tw-rounded tw-border tw-pb-[9px] tw-pt-[10px] tw-font-sans tw-text-sm tw-font-normal tw-leading-6 tw-transition-colors tw-duration-150;
}

.form-control_light {
  @extend .form-control;

  @apply tw-border-hue-200 tw-bg-white focus:tw-border-hue-500 focus:tw-bg-white disabled:tw-bg-hue-200;
}

.form-control_dark {
  @extend .form-control;

  @apply tw-border-hue-500 tw-bg-hue-600 tw-text-hue-200 focus:tw-border-hue-500 focus:tw-bg-hue-500 focus:tw-text-white disabled:tw-bg-hue-500 disabled:tw-text-white;
}

.form-icon {
  @apply tw-absolute tw-left-0 tw-top-0 tw-m-[15px];
}

.form-icon_dark {
  @extend .form-icon;

  @apply tw-text-white;
}

.form-icon_light {
  @extend .form-icon;

  @apply tw-text-black;
}

.form-label {
  @apply tw-font-sans tw-font-semibold;
}

.form-label_dark {
  @apply tw-font-sans tw-font-semibold tw-text-hue-300;
}

.form-select-action-container {
  @apply tw-flex tw-w-10 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-sm tw-border-y tw-border-s tw-border-hue-200;
  background-color: #fcfcfc;
}

.form-select-action-container:hover {
  border-color: #b3b3b3;
}

.form-select-action-container:disabled {
  background-color: #eee;
}

.form-select-action-container:last-child {
  @apply tw-border-e;
  border-radius: 0px 5px 5px 0px;
}

.form-select-action-container_dark {
  @apply tw-flex tw-w-10 tw-cursor-pointer tw-items-center tw-justify-center tw-rounded-sm tw-border-y tw-border-s tw-border-hue-500;
  background-color: rgb(75, 85, 99);
}

.form-select-action-container_dark:hover {
  border-color: #b3b3b3;
}

.form-select-action-container_dark:disabled {
  background-color: #4b5563;
}

.form-select-action-container_dark:last-child {
  @apply tw-border-e;
  border-radius: 0px 5px 5px 0px;
}

// checkbox
.form-check-label {
  @apply tw-mb-1 tw-inline-block tw-font-sans tw-font-medium tw-leading-5 tw-text-hue-500;
}

// React Date Picker leading zero fix (for Safari)
.react-date-picker__inputGroup__leadingZero {
  @apply tw-h-[27px];
}

// display invalid feedback within forms
.invalid-feedback {
  @apply tw-block;
}
