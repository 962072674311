// Keeping

// Dev Menu ---------------------------------------------

.dev-menu-subtitle {
  @apply tw-mb-2 tw-mt-3 tw-font-sans tw-text-sm tw-font-semibold tw-text-hue-700;
}

.dev-menu-subtitle_dark {
  @apply tw-mb-2 tw-mt-3 tw-font-sans tw-text-sm tw-font-semibold tw-text-white;
}

// End ---------------------------------------------

.unAuthUtilityContainer {
  display: flex;
  justify-content: center;
  background-color: #fff;
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.04),
    0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.06);
}

//Home styling

// Errors
.HTTPResponseContainer {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

//------------------------------------------------------------------------------------------------------------

//xs - sm
@media screen and (min-width: 0px) and (max-width: 576px) {
  .viewFormContainer {
    padding: 10px;
  }

  .buttonBarSpacer {
    margin-top: 0px;
    margin-bottom: 20px;
  }

  //Buttons
  .buttonGroupContainer {
    margin-top: 10px;
    display: "flex";
    justify-content: normal !important;
    align-items: "center";
  }

  .datatableContainer {
    padding: 10px;
  }
}

//md
@media screen and (min-width: 577px) and (max-width: 768px) {
  .container {
    max-width: 100%;
    width: 100%;
  }

  //Buttons
  .buttonGroupContainer {
    margin-top: 45px;
    display: "flex";
    justify-content: normal !important;
    align-items: "center";
  }

  .datatableContainer {
    padding: 20px;
  }
}

//lg
// @media screen and (min-width: 769px) and (max-width: 992px) {
//     .contentContainer {
//         margin-left: 0px;
//         padding-left: 0px;
//     }
// }

//xl
@media screen and (min-width: 993px) and (max-width: 1200px) {
}

//xxl
@media screen and (min-width: 1201px) and (max-width: 1400px) {
  .container {
    max-width: 100% !important;
  }
}
