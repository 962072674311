.spark-datetimeformat-wrapper {
  filter: none !important;
}

.spark-datetimeformat-wrapper_dark div button svg {
  filter: contrast(0.5) brightness(5);
}

.react-datetimerange-picker__wrapper,
.react-timerange-picker__wrapper,
.react-daterange-picker__wrapper,
.react-datetime-picker__wrapper,
.react-time-picker__wrapper,
.react-date-picker__wrapper {
  @apply tw-p-3;
  filter: inherit !important;
  color: inherit !important;
}
