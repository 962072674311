.user-box-wrapper {
  @apply tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg hover:tw-transition-all hover:tw-duration-300;
  &:hover {
    @apply tw-transition-all tw-duration-300;
  }
}

.user-box-wrapper_dark {
  @apply tw-flex tw-cursor-pointer tw-items-center tw-justify-between tw-rounded-lg hover:tw-transition-all hover:tw-duration-300;
  &:hover {
    @apply tw-transition-all tw-duration-300;
  }
}

.user-box-name {
  @apply tw-pr-2 tw-font-sans tw-font-medium tw-text-neutral-700 tw-transition-all tw-duration-300;
  &:hover {
    @apply tw-text-neutral-500 tw-transition-all tw-duration-300;
  }
}

.user-box-name_dark {
  @apply tw-pr-2 tw-font-sans tw-font-medium tw-text-neutral-50;
}

// control center ---------------------------------------------------------

.control-center-panel {
  @apply tw-absolute tw-right-6 tw-z-10 tw-mt-4 tw-flex tw-w-screen tw-max-w-max tw-px-4;
}

.control-center-container {
  @apply tw-w-screen tw-max-w-md tw-flex-auto tw-overflow-hidden tw-rounded-xl tw-bg-white tw-text-sm tw-leading-6 tw-shadow-lg tw-ring-1 tw-ring-hue-900/5;
}

.control-center-container_dark {
  @apply tw-w-screen tw-max-w-md tw-flex-auto tw-overflow-hidden tw-rounded-xl tw-bg-hue-900 tw-text-sm tw-leading-6 tw-shadow-lg tw-ring-1 tw-ring-hue-900/5;
}

.control-center-header {
  @apply tw-font-semibold tw-text-hue-900;
}

.control-center-header_dark {
  @apply tw-font-semibold tw-text-neutral-200;
}

.control-center-description {
  @apply tw-mt-1 tw-text-sm tw-text-hue-600;
}

.control-center-description_dark {
  @apply tw-mt-1 tw-text-sm tw-text-neutral-400;
}

.control-center-cta-container {
  @apply tw-grid tw-grid-cols-2 tw-divide-x tw-divide-hue-900/5 tw-bg-hue-50;
}

.control-center-cta-container_dark {
  @apply tw-grid tw-grid-cols-2 tw-divide-x tw-divide-hue-700 tw-bg-hue-600;
}

.control-panel-cta-text {
  @apply tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-gap-x-2.5 tw-p-3 tw-font-semibold tw-text-hue-900 hover:tw-bg-hue-100;
}

.control-panel-cta-text_dark {
  @apply tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-gap-x-2.5 tw-p-3 tw-font-semibold tw-text-neutral-200 hover:tw-bg-hue-700;
}
