.focus-safe-zone {
  @apply tw-flex tw-min-h-screen tw-flex-col tw-items-center tw-justify-start tw-bg-neutral-500 sm:tw-justify-center;
}

.focus-safe-zone_dark {
  @apply tw-flex tw-min-h-screen tw-flex-col tw-items-center tw-justify-start tw-bg-neutral-700 sm:tw-justify-center;
}

.focus-container {
  @apply tw-m-4 tw-flex tw-flex-col tw-rounded-lg tw-bg-white tw-p-3 tw-text-center tw-shadow-lg sm:tw-justify-center md:tw-m-0 md:tw-w-2/3 md:tw-p-8;
}

.focus-container_dark {
  @apply tw-m-4 tw-flex tw-flex-col tw-rounded-lg tw-bg-neutral-800 tw-p-3 tw-text-center tw-shadow-lg sm:tw-justify-center md:tw-m-0 md:tw-w-2/3 md:tw-p-8;
}

.focus-holder {
  @apply tw-flex tw-h-full tw-flex-col tw-items-stretch tw-justify-center;
}

.focus-holder_dark {
  @apply tw-flex tw-h-full tw-flex-col tw-items-stretch tw-justify-center tw-text-white;
}
