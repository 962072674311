// widget containers ----------------------------------------------

.view-container {
  @apply tw-rounded-md tw-border tw-shadow-sm hover:tw-transition-all hover:tw-duration-300 sm:tw-p-7;
}

.view-container_light {
  @apply view-container tw-border-hue-200 tw-bg-white;
}

.view-container_dark {
  @apply view-container tw-bg-hue-800 tw-border-hue-900;
}

.profile-container {
  @apply tw-flex tw-w-full tw-flex-col tw-items-center tw-rounded-lg tw-p-10;
}

.profile-container_dark {
  @apply tw-mb-5 tw-flex tw-w-full tw-flex-col tw-items-center tw-rounded-lg tw-p-10;
}

// page header fonts ----------------------------------------------

.page-header-font {
  @apply tw-text-2xl tw-font-bold tw-leading-7 tw-text-hue-900 sm:tw-truncate sm:tw-text-3xl sm:tw-tracking-tight;
}

.page-header-font_dark {
  @apply tw-text-2xl tw-font-bold tw-leading-7 tw-text-hue-100 sm:tw-truncate sm:tw-text-3xl sm:tw-tracking-tight;
}

.page-header-subtitle-font {
  @apply tw-ml-0 tw-flex tw-items-center tw-border-l-0 tw-border-hue-300 tw-pl-0 tw-font-sans sm:tw-ml-4 sm:tw-border-l-2 sm:tw-pl-4;
}

.page-header-subtitle-font_dark {
  @apply tw-ml-0 tw-flex tw-items-center tw-border-l-0 tw-border-white tw-pl-0 tw-font-sans sm:tw-ml-4 sm:tw-border-l-2 sm:tw-pl-4;
}

// title fonts ----------------------------------------------

.title-font {
  @apply tw-mb-5 tw-font-sans tw-text-xl tw-font-bold tw-text-hue-800;
}

.title-font_dark {
  @apply tw-mb-5 tw-font-sans tw-text-xl tw-font-bold tw-text-hue-100;
}

// action buttons structure ----------------------------------------------

.chevron-selector {
  transition: all 300ms ease-in-out;
  @apply tw-m-auto tw-h-5 tw-w-5 tw-duration-300;

  &:hover {
    @apply tw-cursor-pointer tw-rounded-full tw-bg-hue-200;
  }
}

.chevron-selector_dark {
  transition: all 300ms ease-in-out;
  @apply tw-m-auto tw-h-5 tw-w-5 tw-duration-300;

  &:hover {
    @apply tw-cursor-pointer tw-rounded-full tw-bg-hue-600;
  }
}

// Pagination structure ----------------------------------------------

.pagination-arrow-button {
  transition: all 300ms ease-in-out;
  @apply tw-mr-2 tw-flex tw-cursor-pointer tw-items-center tw-rounded-md tw-border tw-border-hue-300 tw-bg-hue-200 tw-px-4 tw-py-2 tw-duration-300;

  &:hover {
    @apply tw-bg-white;
  }
}

.pagination-arrow-button_dark {
  transition: all 300ms ease-in-out;
  @apply tw-mr-2 tw-flex tw-cursor-pointer tw-items-center tw-rounded-md tw-border tw-border-hue-300 tw-bg-hue-800 tw-px-4 tw-py-2 tw-duration-300;

  &:hover {
    @apply tw-bg-hue-700;
  }
}

.paginate-dropdown {
  @apply tw-h-8 tw-w-20 tw-rounded-md tw-border tw-border-hue-300 tw-bg-white tw-p-1 tw-font-sans tw-text-sm;
}

.paginate-dropdown_dark {
  @apply tw-h-8 tw-w-20 tw-rounded-md tw-border tw-border-hue-300 tw-bg-hue-800 tw-p-1 tw-font-sans tw-text-sm;
}

.paginate-option {
  @apply tw-h-8 tw-w-48 tw-rounded-md tw-border tw-border-hue-300 tw-bg-white tw-p-1 tw-font-sans;
}

.paginate-option_dark {
  @apply tw-h-8 tw-w-48 tw-rounded-md tw-border tw-border-hue-300 tw-bg-hue-800 tw-p-1 tw-font-sans;
}

// Widget meta structure ----------------------------------------------

.widget-title-container {
  @apply tw-mb-5 tw-flex tw-flex-col tw-justify-between xl:tw-flex-row;
}

.widget-subtitle {
  @apply tw-flex tw-text-left tw-font-sans tw-text-sm tw-text-hue-600 xl:tw-max-w-[600px] xl:tw-text-right;
}

.widget-subtitle_light {
  @extend .widget-subtitle;

  @apply tw-text-hue-600;
}

.widget-subtitle_dark {
  @extend .widget-subtitle;

  @apply tw-text-white;
}

// Feedback buttons structure ----------------------------------------------

.feedback-container {
  @apply tw-fixed tw--right-40 tw-flex tw-w-52 tw-cursor-pointer tw-rounded-s-full tw-p-1 tw-transition-all tw-duration-300 hover:tw-right-0;
}

.feedback-icon-holder {
  @apply tw-me-4 tw-rounded-full tw-bg-white tw-p-1.5 tw-shadow-sm;
}

.feedback-text {
  @apply tw-flex tw-items-center tw-text-xs tw-font-semibold tw-uppercase tw-text-white;
}
