//Loader
.spinner-border {
  border-bottom-width: 0px !important;
  border-width: 4px !important;
}

.spinnerBackground {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 85vh;
  border-radius: 10px;
  // background-color: rgb(243 243 243 / 51%);
}

.spinnerBackground-table {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-color: rgb(243 243 243 / 51%);
}

//media queries
//xs - sm
@media screen and (min-width: 0px) and (max-width: 576px) {
}

//md
@media screen and (min-width: 577px) and (max-width: 768px) {
}

//lg
@media screen and (min-width: 769px) and (max-width: 992px) {
}

//xl
@media screen and (min-width: 993px) and (max-width: 1200px) {
}

//xxl
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
