// profile containers ----------------------------------------------

.profile-picture-container-full {
  @apply tw-flex tw-h-full tw-w-full tw-items-center tw-justify-center tw-rounded-lg;
}

.profile-picture-container-large {
  @apply tw-flex tw-h-32 tw-w-32 tw-items-center tw-justify-center tw-rounded-full tw-shadow-xl;
}

.profile-picture-container-medium {
  @apply tw-flex tw-h-20 tw-w-20 tw-items-center tw-justify-center tw-rounded-full tw-shadow-lg;
}

.profile-picture-container-small {
  @apply tw-flex tw-h-10 tw-w-10 tw-items-center tw-justify-center tw-rounded-full tw-shadow-sm;
}

// profile fonts ----------------------------------------------

.profile-picture-text-large {
  @apply tw-font-sans tw-text-5xl tw-font-bold tw-text-hue-700;
}

.profile-picture-text-medium {
  @apply tw-font-sans tw-text-3xl tw-font-bold tw-text-hue-700;
}

.profile-picture-text-small {
  @apply tw-font-sans tw-font-bold tw-text-hue-700;
}

// datatable fonts ----------------------------------------------

.data-table-profile-picture-container-small {
  @apply tw-flex tw-h-7 tw-w-7 tw-items-center tw-justify-center tw-rounded-full tw-font-sans tw-shadow-md;
}

.data-table-profile-picture-text-small {
  @apply tw-font-sans tw-text-xs tw-font-semibold tw-text-hue-700;
}
