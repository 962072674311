.contact-card {
  @apply tw-relative tw-overflow-hidden tw-rounded-lg tw-bg-white tw-text-left tw-shadow-md hover:tw-shadow-lg hover:tw-transition-all hover:tw-duration-300;
}

.contact-card_dark {
  @apply tw-relative tw-overflow-hidden tw-rounded-lg tw-border tw-border-hue-500 tw-bg-hue-800 tw-text-left tw-shadow-md hover:tw-shadow-lg hover:tw-transition-all hover:tw-duration-300;
}

.contact-card-header {
  @apply tw-flex tw-justify-between  tw-bg-hue-50 tw-p-5 tw-font-sans tw-font-medium tw-text-neutral-700;
}

.contact-card-header_dark {
  @apply tw-flex tw-justify-between tw-bg-hue-700 tw-p-5 tw-font-sans tw-font-medium tw-text-white;
}

.contact-card-data-row {
  @apply tw-flex tw-justify-between tw-border-t tw-border-hue-100 tw-p-4 tw-text-sm tw-font-medium tw-text-hue-700;
}

.contact-card-data-row_dark {
  @apply tw-flex tw-justify-between tw-border-t tw-border-hue-500 tw-p-4 tw-text-sm tw-font-medium tw-text-white;
}
