// Importing Fonts
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

// Importing Tailwind
@tailwind base;
@tailwind components;
@tailwind utilities;

// Core Bootstrap Imports
@import "~bootstrap/scss/bootstrap";

// Importing style blocks
@import "HTMLOverrides";
@import "buttons";
@import "unauthed";
@import "forms";
@import "spinner";
@import "counter";
@import "data-badge";
@import "stepBar";
@import "formulaBuilder";
@import "datepicker";
@import "developer-menu";
@import "sider";
@import "structure";
@import "modal";
@import "profile-picture";
@import "focus-mode";
@import "step-assistant";
@import "profile-invite";
@import "header";
@import "contact-card";
@import "general-tabs";
@import "accordion";
@import "datetimerangepicker";
@import "utility";
@import "datatable";
