.general-tab-list {
  @apply tw-flex tw-space-x-1 tw-rounded-md tw-rounded-b-none tw-bg-hue-200 tw-p-1.5;
}

.general-tab-list_dark {
  @apply tw-flex tw-space-x-1 tw-rounded-md tw-rounded-b-none tw-bg-hue-800 tw-p-1.5;
}

.general-tab-overflow-button {
  @apply tw-flex tw-space-x-1 tw-rounded-md tw-bg-white tw-p-1 tw-outline-none;
}

.general-tab-overflow-button_dark {
  @apply tw-flex tw-space-x-1 tw-rounded-md tw-bg-hue-800 tw-p-1 tw-outline-none;
}

// base class for tab
.general-tab {
  @apply tw-w-full tw-rounded-lg tw-py-2.5 tw-text-sm tw-font-medium tw-leading-5 tw-ring-opacity-60 tw-ring-offset-2 focus:tw-outline-none;
}

// base class for selected tab
.general-tab_selected {
  @apply tw-w-full tw-rounded-lg tw-text-sm tw-font-medium tw-leading-5 tw-shadow tw-ring-opacity-60 tw-ring-offset-2 focus:tw-outline-none;
}

// tab with light theme
.general-tab_light {
  @apply general-tab tw-text-hue-500 tw-ring-white tw-ring-offset-hue-400 hover:tw-bg-white/[0.12] hover:tw-text-hue-900;
}

// selected tab with light theme
.general-tab_selected_light {
  @apply general-tab_selected tw-bg-white tw-text-hue-700 tw-ring-white tw-ring-offset-hue-400;

}

// tab with dark theme
.general-tab_dark {
  @apply general-tab tw-text-hue-200 tw-ring-white tw-ring-offset-hue-400 hover:tw-bg-white/[0.12] hover:tw-text-white;
}

// selected tab with dark theme
.general-tab_selected_dark {
  @apply general-tab_selected tw-bg-white tw-text-hue-700 tw-shadow tw-ring-white tw-ring-offset-hue-400;
}

.general-tab-overflow-container {
  padding-bottom: -20px;
  @apply tw-relative tw-grid tw-rounded-2xl tw-bg-white tw-p-4 tw-shadow;
}

.general-tab-overflow-container_dark {
  @apply tw-relative tw-grid tw-rounded-2xl tw-border tw-border-hue-700 tw-bg-hue-800 tw-p-4;
}

.general-tab-overflow {
  min-width: 150px;
  @apply tw-mb-2 tw-cursor-pointer tw-rounded-xl tw-border tw-border-hue-200 tw-bg-hue-50 tw-p-2 tw-px-4 tw-text-center tw-font-sans tw-text-sm tw-font-medium tw-text-hue-600 tw-outline-none hover:tw-bg-hue-100;
}

.general-tab-overflow_dark {
  min-width: 150px;
  @apply tw-mb-2 tw-cursor-pointer tw-rounded-xl tw-border tw-border-hue-700 tw-bg-hue-700 tw-p-2 tw-px-4 tw-text-center tw-font-sans tw-text-sm tw-font-medium tw-text-white tw-outline-none hover:tw-bg-hue-500;
}

.general-tab-overflow_selected {
  @apply tw-mb-2 tw-cursor-pointer tw-rounded-xl tw-border tw-border-hue-200 tw-bg-hue-200 tw-p-2 tw-text-center tw-font-sans tw-text-sm tw-font-medium tw-text-hue-500 tw-outline-none hover:tw-bg-hue-300;
}

.general-tab-overflow_selected_dark {
  @apply tw-mb-2 tw-cursor-pointer tw-rounded-xl tw-border tw-border-hue-200 tw-bg-hue-200 tw-p-2 tw-text-center tw-font-sans tw-text-sm tw-font-medium tw-text-hue-500 tw-outline-none hover:tw-bg-hue-300;
}

.general-tab-trigger {
  @apply tw-w-full tw-rounded-lg tw-text-center tw-text-sm tw-font-medium tw-leading-5 tw-text-hue-500 tw-ring-white tw-ring-opacity-60 tw-ring-offset-2 hover:tw-bg-white/[0.12] hover:tw-text-hue-900;

  button {
    @apply tw-py-2.5 tw-text-center focus:tw-outline-none;
  }
}

.general-tab-trigger_dark {
  @apply tw-w-full tw-rounded-lg tw-text-sm tw-font-medium tw-leading-5 tw-text-hue-200 tw-ring-white tw-ring-opacity-60 tw-ring-offset-2 tw-ring-offset-hue-400 hover:tw-bg-white/[0.12] hover:tw-text-white;

  button {
    @apply tw-w-full tw-py-2.5 tw-text-center focus:tw-outline-none;
  }
}

.general-tab-content-holder {
  @apply tw-p-0 sm:tw-p-0 view-container_light;
}

.general-tab-content-holder_dark {

  @apply tw-p-0 sm:tw-p-0 view-container_dark;
}
