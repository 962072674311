//Unauthed
.unAuthBackground {
  height: 100vh;
  width: 100vw;
  align-items: center;
  display: flex;
  justify-content: center;
  background-color: #f8f8f8;
  font-family: "Outfit";
  background-color: #f8f8f8;
}

.unAuthContainer {
  width: 60%;
  display: flex;
  justify-content: center;
  background-color: #fff;
  box-shadow:
    0px 0px 1px rgba(0, 0, 0, 0.04),
    0px 2px 6px rgba(0, 0, 0, 0.04),
    0px 16px 24px rgba(0, 0, 0, 0.06);
}

.loginImage {
  height: 100%;
  width: 100%;
  background-size: cover;
}

.unAuthLoginTextArea {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding-top: 100px;
  padding-bottom: 100px;
}

.loginField {
  box-shadow: none;
  border: 0px solid #e7e9eb !important ;
  background-color: #f8fafc !important;
}

// Spolt Redesign
.loggedOutBackground {
  height: 100vh;
  width: 100vw;
  background-color: #f8f8f8;
  font-family: "Outfit";
  background-color: #f8f8f8;
}

.loggedOutTextArea {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex-direction: column;
  padding: 75px;
}

//media queries
//xs - sm
@media screen and (min-width: 0px) and (max-width: 576px) {
  .unAuthBackground {
    height: 100vh;
    width: 100vw;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    background-color: #f8f8f8;
  }

  .unAuthContainer {
    margin-top: 40px;
    height: auto;
    width: 90%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow:
      0px 16px 24px rgba(0, 0, 0, 0.06),
      0px 2px 6px rgba(0, 0, 0, 0.04),
      0px 0px 1px rgba(0, 0, 0, 0.04);
  }
  .loginImage {
    height: 75px;
    min-width: auto;
  }

  .loggedOutTextArea {
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: auto;
    padding: 5px;
  }

  .unAuthLoginTextArea {
    padding-top: 20px;
    padding-bottom: 20px;
    min-width: auto;
  }
}

//md
@media screen and (min-width: 577px) and (max-width: 768px) {
  .unAuthBackground {
    height: 100vh;
    width: 100vw;
    align-items: flex-start;
    display: flex;
    justify-content: center;
    background-color: #f8f8f8;
  }

  .unAuthContainer {
    margin-top: 40px;
    height: auto;
    width: 90%;
    display: flex;
    justify-content: center;
    background-color: #fff;
    box-shadow:
      0px 0px 1px rgba(0, 0, 0, 0.04),
      0px 2px 6px rgba(0, 0, 0, 0.04),
      0px 16px 24px rgba(0, 0, 0, 0.06);
  }

  .loginImage {
    height: 150px;
  }
}

//lg
@media screen and (min-width: 769px) and (max-width: 992px) {
}

//xl
@media screen and (min-width: 993px) and (max-width: 1200px) {
}

//xxl
@media screen and (min-width: 1201px) and (max-width: 1400px) {
}
