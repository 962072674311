.button-core {
  @apply tw-flex
  tw-items-center
  tw-justify-center
  tw-rounded
  tw-px-3
  tw-py-2
  tw-font-sans
  tw-text-sm
  tw-font-semibold;
  font-family: "Inter";
}

.button-regular {
  @extend .button-core;
  @apply tw-border
  tw-border-hue-200
        tw-bg-hue-100 
        tw-text-hue-800 
        hover:tw-bg-hue-200 
        disabled:tw-bg-hue-300
        disabled:tw-text-hue-400;
}

.button-regular_dark {
  @extend .button-core;
  @apply tw-flex
        tw-border
        tw-border-hue-600
        tw-bg-hue-900
        tw-text-white
        hover:tw-bg-white
        hover:tw-text-hue-800 
        hover:tw-shadow-md 
        disabled:tw-bg-hue-700 
        disabled:tw-text-hue-500;
}

.button-primary {
  @extend .button-core;
  @apply tw-bg-primary_accent
        tw-text-white 
        hover:tw-bg-primary_accent_hover;
}

.button-secondary {
  @extend .button-core;
  @apply tw-bg-secondary_accent
        tw-text-white 
        hover:tw-bg-secondary_accent_hover;
}

// For now button secondary is the same as button regular dark
.button-secondary_dark {
  @extend .button-regular_dark;
}

.button-warning {
  @extend .button-core;
  @apply tw-bg-yellow-400
        tw-text-black 
        hover:tw-bg-yellow-500 
        disabled:tw-bg-yellow-300;
}

.button-danger {
  @extend .button-core;
  @apply tw-bg-red-500
        tw-text-white 
        hover:tw-bg-red-600 
        disabled:tw-bg-red-400;
}

.button-link {
  min-height: 36px;
  @apply tw-flex
        tw-items-center
        tw-justify-center
        tw-px-4
        tw-py-2
        tw-font-sans
        tw-font-semibold 
        tw-text-hue-800 
        tw-underline;
}

.button-link_dark {
  min-height: 36px;
  @apply tw-flex
        tw-items-center
        tw-justify-center
        tw-px-4
        tw-py-2
        tw-font-sans
        tw-font-semibold
        tw-text-hue-200
        tw-underline;
}

.button-outlined {
  @extend .button-core;
  @apply tw-border
        tw-border-hue-500
        tw-bg-transparent
        tw-text-hue-800 
        hover:tw-bg-hue-400 
        disabled:tw-bg-hue-400;
}

.button-outlined_dark {
  @extend .button-core;
  @apply tw-border
        tw-border-hue-400
        tw-bg-transparent
        tw-text-hue-200
        hover:tw-bg-hue-400
        disabled:tw-bg-hue-400;
}

.button-return {
  @extend .button-core;
  @apply tw-bg-transparent
        tw-text-hue-800 
        hover:tw-bg-hue-400 
        disabled:tw-bg-hue-400;
  &:hover {
    @apply tw-bg-white tw-text-hue-500;
  }
}

.button-return_dark {
  @extend .button-core;
  @apply tw-bg-transparent
        tw-text-hue-200 
        hover:tw-bg-hue-400 
        disabled:tw-bg-hue-400;
  &:hover {
    @apply tw-bg-hue-400 tw-text-hue-800;
  }
}
