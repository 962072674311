p {
  margin: 0px;
  font-size: 16px;
  font-family: "Inter";
}

h1 {
  @apply tw-m-0 tw-font-sans tw-text-xl tw-font-bold;
  font-family: "Inter";
}

h3 {
  @apply tw-text-2xl tw-font-bold;
  font-family: "Inter";
}

h4 {
  @apply tw-text-xl tw-font-semibold;
  font-family: "Inter";
}

h5 {
  @apply tw-text-lg tw-font-bold;
  font-family: "Inter";
}

h6 {
  @apply tw-text-base tw-font-bold;
  font-family: "Inter";
}

.hr {
  color: rgb(167, 167, 167);
}

ul.nobullets {
  list-style-type: none;
}

a:link {
  text-decoration: none;
}

a:visited {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

a:active {
  text-decoration: none;
}

// tabs
.nav-tabs {
  border-radius: 10px;
  background-color: #e0e0e0;
}

// DT
// removes the chin from the actions on datatables
.list-unstyled {
  margin-bottom: 0px !important;
}

// toggle
.dark-toggle .react-toggle-track {
  background-color: #777777;
}
