.counterbox {
  background-color: rgb(227, 227, 227);
  width: 100px;
  margin-bottom: 20px;
  border-radius: 10px;
  text-align: center;
  padding: 10px;

  &.sm {
    width: 50px;
  }

  &.lg {
    width: 200px;
  }

  &.xl {
    width: 400px;
  }

  &.fw {
    width: 100%;
  }
}
