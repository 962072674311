// this document serves as a style sheet for anything date/time picker related

// Handles style when the data/time picker is-invalid
.react-date-picker.is-invalid,
.react-time-picker.is-invalid,
.react-datetime-picker.is-invalid,
.react-daterange-picker.is-invalid,
.react-timerange-picker.is-invalid,
.react-datetimerange-picker.is-invalid {
  :first-child {
    border-color: #f44336 !important;
  }
}

.react-date-picker__wrapper,
.react-time-picker__wrapper,
.react-datetime-picker__wrapper,
.react-daterange-picker__wrapper,
.react-timerange-picker__wrapper,
.react-datetimerange-picker__wrapper {
  margin-top: 1px;
  padding-top: 7px !important;
  padding-bottom: 7px !important;
  padding: 4px;
  border: thin solid #dedede !important;
  border-radius: 5px;
  filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg)
    brightness(118%) contrast(119%) huescale(100%);
}

.react-calendar,
.react-timerange-picker__clock {
  border: thin solid #dedede !important;
  border-radius: 5px;
  padding: 10px;
}

.react-date-picker__calendar--open,
.react-date-picker__calendar--closed {
  position: absolute;
}

.spark-date-picker {
  @apply tw-rounded-md tw-border tw-border-hue-300 tw-bg-white tw-shadow-sm;
}

.spark-date-picker_dark {
  @apply tw-rounded-md tw-border tw-border-neutral-700 tw-bg-neutral-800 tw-shadow-sm #{!important};

  button {
    background-color: #333 !important;
    color: #fff !important;
    border: none !important;

    &:hover {
      background-color: #555 !important;
    }

    &:focus {
      background-color: #a1a1a1 !important;
    }
  }
}

.quick-selection-picker {
  @apply tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-bg-hue-50 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-hue-900 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-hue-300 hover:tw-bg-hue-100;
}

.quick-selection-picker_dark {
  @apply tw-inline-flex tw-w-full tw-justify-center tw-gap-x-1.5 tw-rounded-md tw-bg-hue-800 tw-px-3 tw-py-2 tw-text-sm tw-font-semibold tw-text-hue-100 tw-shadow-sm tw-ring-1 tw-ring-inset tw-ring-hue-500 hover:tw-bg-hue-700;
}
