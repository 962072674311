.dataBadgeHolder {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: center;
}

.data-badge-container {
  @apply tw-flex tw-h-10 tw-min-w-full tw-items-center tw-rounded-full tw-bg-white;
}

.data-badge-container_dark {
  @apply tw-flex tw-h-10 tw-min-w-full tw-items-center tw-rounded-full tw-bg-hue-800;
}

.data-badge-badge {
  @apply tw-ml-1 tw-flex tw-h-8 tw-content-center tw-items-center tw-whitespace-nowrap tw-rounded-full tw-bg-neutral-600 tw-p-4 tw-text-sm tw-font-medium tw-text-white;
  font-family: "Outfit";
}

// when screen is small
@media (max-width: 1450px) {
  .dataBadgeHolder {
    // padding: 0px 50px 50px 50px;
    justify-content: center;
  }
}
