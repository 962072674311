.spark-modal {
  @apply tw-fixed tw-inset-0 tw-z-10 tw-w-screen;

  .inner {
    @apply tw-flex tw-min-h-full tw-items-center tw-justify-center tw-p-4 tw-text-center sm:tw-items-center sm:tw-p-0;
  }

  .panel {
    @apply tw-relative tw-mx-auto tw-flex tw-w-full tw-transform tw-flex-col tw-justify-between tw-overflow-visible tw-rounded-lg tw-text-left tw-shadow-xl tw-transition-all;
  }

  .body {
    @apply tw-rounded-lg tw-px-4 tw-pb-4 tw-pt-5 sm:tw-p-6 sm:tw-pb-4;
  }

  .body-inner {
    @apply tw-mt-3 tw-w-full tw-text-center sm:tw-mt-0 sm:tw-text-left;
  }

  .dialog-title {
    @apply tw-mb-5 tw-flex tw-w-full tw-items-center tw-justify-between tw-text-xl tw-font-semibold tw-leading-6 tw-text-hue-900;
  }

  .close-icon {
    @apply tw-ml-2 tw-inline-block tw-cursor-pointer;
  }

  .footer {
    @apply tw-rounded-b-lg tw-px-4 tw-py-3 sm:tw-flex sm:tw-flex-row-reverse sm:tw-px-6;
  }
}

.spark-modal_light {
  @extend .spark-modal;

  .panel {
    @apply tw-bg-white;
  }

  .body {
    @apply tw-bg-white;
  }

  .footer {
    @apply tw-bg-hue-50;
  }

  .close-icon {
    @apply tw-text-hue-500;
  }
}

.spark-modal_dark {
  @extend .spark-modal;
  @apply tw-text-white;

  .panel {
    @apply tw-bg-hue-700;
  }

  .body {
    @apply tw-bg-hue-700;
  }

  .footer {
    @apply tw-bg-hue-800;
  }

  .dialog-title {
    @apply tw-text-white;
  }

  .close-icon {
    @apply tw-text-white;
  }
}
