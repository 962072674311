.ps-menu-button {
  @apply tw-font-sans;
}

.ps-menu-label {
  font-size: 15px;
}

.ps-sidebar-container {
  background-color: transparent !important;
}

.ps-sidebar-root {
  border: none !important;
}
